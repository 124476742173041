export function HotelLogo({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      fill={fillColor}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 350.6108 202.7383"
    >
      <g>
        <g>
          <path d="M194.5357,49.2543c0-7.2151-4.932-12.9951-13.912-15.7857V17.0508h16.637V57.2655h-5.202c1.829-2.576,2.477-5.4991,2.477-8.0112M171.5807,0c-30.158,0-52.372,19.1652-52.372,39.6461,0,10.5819,5.965,19.4944,13.471,25.6724l.57-.388c-5.954-4.918-11.328-12.6341-11.328-23.1994,1.573-22.4059,29.212-35.0373,53.772-35.0373,17.389,0,48.613,4.0177,48.613,33.0741,0,15.1613-16.205,34.8786-50.219,34.8786-17.421,0-30.954-9.328-30.954-22.7309,0-11.6092,9.087-19.8728,22.643-19.8728,3.736,0,7.237,.8726,9.905,1.5982-5.653-1.0179-14.428,.4343-18.97,4.001v19.6235h16.657v-22.3081c3.143,.5668,5.577,1.7433,7.255,2.7896v19.5185h4.34c-3.412,3.826-9.765,6.155-15.268,5.299v.821c15.828,1.245,22.525-6.26,22.525-14.7031,0-9.9333-9.324-15.767-18.852-16.9614v-14.6702h-16.657v14.8448c-10.779,2.1703-21.331,8.5747-21.331,19.1811,0,16.3278,22.826,26.4438,42.682,26.4438,30.179,0,53.403-14.589,53.403-35.0565C231.4657,17.6848,197.9377,0,171.5807,0" />
          <path d="M224.0257,73.4275c0-2.051,1.659-3.717,3.737-3.717,2.056,0,3.715,1.666,3.715,3.717,0,2.07-1.659,3.733-3.715,3.733-2.078,0-3.737-1.663-3.737-3.733m6.795,0c0-1.685-1.367-3.062-3.058-3.062-1.701,0-3.08,1.377-3.08,3.062,0,1.703,1.379,3.079,3.08,3.079,1.691,0,3.058-1.376,3.058-3.079m-3.855,.245v1.893h-.678v-4.378h1.412c.805,0,1.635,.218,1.635,1.209,0,.505-.313,.902-.895,1.02v.02c.606,.119,.68,.386,.743,.859,.066,.418,.108,.876,.259,1.27h-.872c-.044-.247-.118-.525-.151-.781-.042-.377-.042-.725-.248-.933-.161-.179-.387-.157-.624-.179h-.581Zm.721-.681c.646-.023,.797-.287,.797-.626,0-.327-.151-.495-.7-.495h-.818v1.121h.721Z" />
          <polygon points="99.8737 100.2075 99.8737 157.3995 104.3107 157.9915 104.3107 161.3425 82.3287 161.3425 82.3287 157.9915 87.0597 157.3995 87.0597 131.3775 58.9667 131.3775 58.9667 157.3995 63.3037 157.9915 63.3037 161.3425 41.7173 161.3425 41.7173 157.9915 46.0541 157.3995 46.0541 100.2075 41.7173 99.6155 41.7173 96.3605 63.3037 96.3605 63.3037 99.6155 58.9667 100.2075 58.9667 124.6695 87.0597 124.6695 87.0597 100.2075 82.3287 99.6155 82.3287 96.3605 104.3107 96.3605 104.3107 99.6155 99.8737 100.2075 99.8737 100.2075" />
          <path d="M129.0297,157.7945l4.14,.592v2.956h-20.209v-2.956l4.142-.592v-39.141l-4.142-.593v-2.959h16.069v42.693h0Zm1.479-57.291c0,4.339-2.958,7.594-7.001,7.594-4.139,0-6.997-2.958-6.997-7.297,0-4.144,2.858-7.399,6.997-7.399,4.043,0,7.001,3.255,7.001,7.102h0Z" />
          <polygon points="141.9467 158.3865 145.9877 157.7945 145.9877 97.9375 141.9467 97.3465 141.9467 94.3875 158.0127 94.3875 158.0127 157.7945 162.1527 158.3865 162.1527 161.3425 141.9467 161.3425 141.9467 158.3865 141.9467 158.3865" />
          <path d="M186.7027,115.1015h10.843v4.341h-10.843v27.509c0,7.097,1.478,9.365,7.887,9.365,2.365,0,4.435,0,6.408-.494v3.155c-5.817,2.07-10.747,2.957-14.886,2.957-9.265,0-11.435-4.24-11.435-12.322v-30.17h-8.476v-4.341h8.772v-9.667l11.73-3.55v13.217h0Z" />
          <path d="M251.6357,138.6705c0,14.392-9.657,24.053-24.838,24.053-14.687,0-24.052-6.309-24.052-24.053,0-14.888,9.661-24.851,24.84-24.851,14.984,0,24.05,8.976,24.05,24.851h0Zm-13.208-.295c0-14.593-4.334-21.006-11.335-21.006-6.8,0-11.138,6.117-11.138,21.006,0,14.687,4.338,20.799,11.138,20.799,7.296,0,11.335-5.816,11.335-20.799h0Z" />
          <path d="M307.6797,158.3865v2.956h-20.208v-2.956l4.042-.592v-26.319c0-7.299-2.661-10.554-8.181-10.554-2.561,0-6.605,1.775-9.561,3.847v33.026l4.139,.592v2.956h-20.306v-2.956l4.142-.592v-38.846l-4.142-.593v-3.254h16.167v6.412c5.027-4.044,9.856-7.299,16.657-7.299,9.068,0,13.112,5.326,13.112,15.782v27.798l4.139,.592h0Z" />
          <g>
            <path d="M5.2085,202.6182c-2.0161,0-3.7446-.4561-5.2085-1.1523l.48-2.9043c1.3921,.8164,3.1201,1.4639,4.7764,1.4639,1.4644,0,2.4722-.6963,2.4722-2.0156,0-1.2246-.5762-1.7285-2.9761-2.7607-3.2163-1.3203-4.5127-2.376-4.5127-4.9443,0-2.9277,2.2563-4.6328,5.3525-4.6328,1.8721,0,3.1685,.3848,4.3687,1.0088l-.48,2.8799c-1.2002-.792-2.6162-1.2959-3.9126-1.2959-1.584,0-2.2803,.7676-2.2803,1.7764,0,1.0557,.5283,1.5596,2.8325,2.5195,3.4082,1.3926,4.6562,2.5684,4.6562,5.1367,0,3.2402-2.376,4.9209-5.5684,4.9209Z" />
            <path d="M13.9678,202.4258v-16.5615h9.625v2.6885h-6.4805v3.8887h4.9922l.4321,2.7354h-5.4243v4.5605h6.7207v2.6885H13.9678Z" />
            <path d="M32.1362,202.4258h-5.2568v-16.5615h5.2568c5.7603,0,8.7363,3.3848,8.7363,8.2568,0,4.9199-3.0962,8.3047-8.7363,8.3047Zm-.0244-13.9453h-2.064v11.3291h2.0161c3.4805,0,5.5684-1.7764,5.5684-5.6641,0-3.8164-2.208-5.665-5.5205-5.665Z" />
            <path d="M51.3604,202.7383c-4.7046,0-7.7529-3.457-7.7529-8.5693,0-5.1367,3.0723-8.6162,7.8008-8.6162,4.7524,0,7.7524,3.4561,7.7524,8.5684s-3.0479,8.6172-7.8003,8.6172Zm.0239-14.5459c-2.8325,0-4.5366,2.3281-4.5366,5.9531s1.7041,5.9521,4.5366,5.9521c2.856,0,4.5605-2.3281,4.5605-5.9521s-1.7285-5.9531-4.5605-5.9531Z" />
            <path d="M73.248,202.4258l-7.8003-11.4014v11.4014h-2.9526v-16.5615h3.0005l7.4404,10.8486v-10.8486h2.9526v16.5615h-2.6406Z" />
            <path d="M91.1279,202.5938l-1.5845-4.3682h-6.2642l-1.5122,4.2002h-3.1445l6.2168-16.6338h3.3843l6.0723,16.2256-3.168,.5762Zm-4.7285-13.3447l-2.2563,6.3838h4.5127l-2.2563-6.3838Z" />
            <path d="M114.8159,201.9941l-3.2402,.5996-4.3208-6.5049h-1.4399v6.3369h-3.1685v-16.5615h5.1367c3.6963,0,5.9282,1.752,5.9282,4.9688,0,2.4004-1.272,3.9121-3.312,4.6562l4.4165,6.5049Zm-7.2729-13.5859h-1.752v5.2568h1.6079c1.9683,0,3.1924-.9365,3.1924-2.6641,0-1.6562-1.0562-2.5928-3.0483-2.5928Z" />
            <path d="M117.3579,202.4258v-16.5615h9.625v2.6885h-6.4805v3.8887h4.9922l.4321,2.7354h-5.4243v4.5605h6.7207v2.6885h-9.8652Z" />
            <path d="M134.7827,202.6182c-2.0161,0-3.7446-.4561-5.2085-1.1523l.48-2.9043c1.3921,.8164,3.1201,1.4639,4.7764,1.4639,1.4644,0,2.4722-.6963,2.4722-2.0156,0-1.2246-.5762-1.7285-2.9761-2.7607-3.2163-1.3203-4.5127-2.376-4.5127-4.9443,0-2.9277,2.2563-4.6328,5.3525-4.6328,1.8721,0,3.1685,.3848,4.3687,1.0088l-.48,2.8799c-1.2002-.792-2.6162-1.2959-3.9126-1.2959-1.584,0-2.2803,.7676-2.2803,1.7764,0,1.0557,.5283,1.5596,2.8325,2.5195,3.4082,1.3926,4.6562,2.5684,4.6562,5.1367,0,3.2402-2.376,4.9209-5.5684,4.9209Z" />
            <path d="M150.6465,202.7383c-4.7046,0-7.7529-3.457-7.7529-8.5693,0-5.1367,3.0723-8.6162,7.8008-8.6162,4.7524,0,7.7524,3.4561,7.7524,8.5684s-3.0479,8.6172-7.8003,8.6172Zm.0239-14.5459c-2.8325,0-4.5366,2.3281-4.5366,5.9531s1.7041,5.9521,4.5366,5.9521c2.8555,0,4.5605-2.3281,4.5605-5.9521s-1.7285-5.9531-4.5605-5.9531Z" />
            <path d="M173.9507,201.9941l-3.2402,.5996-4.3213-6.5049h-1.4395v6.3369h-3.1689v-16.5615h5.1367c3.6963,0,5.9287,1.752,5.9287,4.9688,0,2.4004-1.2725,3.9121-3.3125,4.6562l4.417,6.5049Zm-7.2734-13.5859h-1.752v5.2568h1.6084c1.9678,0,3.1924-.9365,3.1924-2.6641,0-1.6562-1.0566-2.5928-3.0488-2.5928Z" />
          </g>
          <path d="M183.1821,188.625v13.8008h-3.1924v-13.8008h-4.9199v-2.7607h12.8652l.4561,2.7607h-5.209Z" />
          <g>
            <path d="M207.9995,202.5938l-1.584-4.3682h-6.2646l-1.5117,4.2002h-3.1445l6.2168-16.6338h3.3838l6.0732,16.2256-3.1689,.5762Zm-4.7285-13.3447l-2.2559,6.3838h4.5127l-2.2568-6.3838Z" />
            <path d="M218.9194,188.625v13.8008h-3.1924v-13.8008h-4.9199v-2.7607h12.8652l.4561,2.7607h-5.209Z" />
          </g>
          <g>
            <path d="M237.2368,202.4258h-4.7529v-16.5615h4.8242c3.7207,0,5.4971,1.6807,5.4971,4.2969,0,1.7275-.96,2.8555-2.4004,3.4561,1.8477,.5762,3.0723,1.7764,3.0723,3.9844,0,3.2637-2.3525,4.8242-6.2402,4.8242Zm-.0967-14.1377h-1.6074v4.3926h1.4639c1.8721,0,2.8564-.8398,2.8564-2.2559,0-1.3203-.792-2.1367-2.7129-2.1367Zm.0488,6.6494h-1.6562v5.0645h1.7041c2.0156,0,3.1924-.8408,3.1924-2.4971,0-1.6797-1.1289-2.5674-3.2402-2.5674Z" />
            <path d="M246.5474,202.4258v-16.5615h9.625v2.6885h-6.4805v3.8887h4.9922l.4326,2.7354h-5.4248v4.5605h6.7207v2.6885h-9.8652Z" />
            <path d="M259.4595,202.4258v-16.5615h3.1924v13.7529h5.5205l.4316,2.8086h-9.1445Z" />
            <path d="M271.3872,202.4258v-16.5615h3.1924v13.7529h5.5205l.4316,2.8086h-9.1445Z" />
            <path d="M301.0991,201.9941l-3.2402,.5996-4.3203-6.5049h-1.4395v6.3369h-3.1689v-16.5615h5.1367c3.6963,0,5.9287,1.752,5.9287,4.9688,0,2.4004-1.2725,3.9121-3.3125,4.6562l4.416,6.5049Zm-7.2725-13.5859h-1.752v5.2568h1.6084c1.9678,0,3.1924-.9365,3.1924-2.6641,0-1.6562-1.0566-2.5928-3.0488-2.5928Z" />
            <path d="M310.7466,202.7383c-4.7041,0-7.752-3.457-7.752-8.5693,0-5.1367,3.0723-8.6162,7.7998-8.6162,4.7529,0,7.7529,3.4561,7.7529,8.5684s-3.0479,8.6172-7.8008,8.6172Zm.0244-14.5459c-2.832,0-4.5361,2.3281-4.5361,5.9531s1.7041,5.9521,4.5361,5.9521c2.8564,0,4.5605-2.3281,4.5605-5.9521s-1.7285-5.9531-4.5605-5.9531Z" />
            <path d="M329.0825,202.7139c-4.8252,0-7.8486-3.4082-7.8486-8.5449s3.1436-8.5928,7.8242-8.5928c1.9199,0,3.4082,.4561,4.6328,1.2002l-.4561,3c-1.0801-.8154-2.4248-1.4639-4.0322-1.4639-2.9287,0-4.7285,2.2324-4.7285,5.833,0,3.6475,1.9199,5.8076,4.8477,5.8076,1.5361,0,2.9287-.5518,4.2012-1.3916l.4316,2.7363c-1.3203,.8398-3,1.416-4.8721,1.416Z" />
            <path d="M347.2271,202.5938l-6.6729-8.2324v8.0645h-3.1924v-16.5615h3.1924v7.2725l6.0967-7.2725h3.6484l-6.5771,7.6572,6.8887,8.4727-3.3838,.5996Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
